import Splide from '@splidejs/splide';

const slider = document.querySelectorAll('.js-building-splide');

const options = {
  type: 'loop',
  arrows: false,
  gap: '0.25rem',
  classes: {
    pagination: 'splide__pagination splide__pagination--gold',
    page: 'splide__pagination__page splide__pagination__page--gold',
  },
};

if (slider || slider.length) {
  slider.forEach((index) => {
    new Splide(index, options).mount();
  });
}

function setDetails(event) {
  const allDetails = document.querySelectorAll('.js-details');
  if (allDetails || allDetails.length) {
    allDetails.forEach((index) => {
      index.classList.remove('active');
    });
  }

  if (location.hash) {
    const buildingDetails = document.querySelector(`${location.hash}`);
    if (buildingDetails) {
      buildingDetails.classList.add('active');
    }
  }
}

document.addEventListener('DOMContentLoaded', setDetails);
window.addEventListener('hashchange', setDetails);
